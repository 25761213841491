   .gallery-image img {
       width: 100% !important;
       height: auto !important;
   }

   .gallery-container{
       margin-top: 20px;
   }

   @media screen and (min-width: 00px) and (max-width: 500px) {

       .gallery-container {

            display:inline-block;
            margin-right: auto;
            margin-left: auto;

            width: 100%;
            height: 100%;
            padding: 40px;

            line-height: 0;
            -webkit-column-count: 1;
            -webkit-column-gap: 10px;
            -moz-column-count: 1;
            -moz-column-gap: 10px;
            column-count: 1;
            column-gap: 10px;
            background-color: #323e42;
            box-shadow: 0 0 20px;
            border-radius: 10px;
       }
   }

@media screen and (min-width: 500px) and (max-width: 950px){

    .gallery-container {
        display:inline-block;
        margin-right: auto;
        margin-left: auto;

        width: 700px;
        height: 100%;
        padding: 40px;

        line-height: 0;
        -webkit-column-count: 2;
        -webkit-column-gap: 10px;
        -moz-column-count: 2;
        -moz-column-gap: 10px;
        column-count: 2;
        column-gap: 10px;
        background-color: #323e42;
        box-shadow: 0 0 20px;
        border-radius: 10px;
       }
}


@media screen and (min-width: 950px) {
    .gallery-container {
        width: 950px;
        overflow: scroll;
        line-height: 0;
        -webkit-column-count: 3;
        -webkit-column-gap: 10px;
         -moz-column-count: 3;
        -moz-column-gap: 10px;
        column-count: 3;
        column-gap: 10px;
        background-color: #323e42;
        box-shadow: 0 0 20px;
        border-radius: 10px;;
        padding: 20px;
    }

}

    .gallery-image {
        padding-top: 10px;
    }

    .gallery-image img {
        border-radius: 10px;
    }

    .error-container{
        width: 90%;
        height: 80%;
        background-color:#323e42;
        border-radius: 10px;
        box-shadow: 0 0 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        align-self: flex-start;
    }

    .error-loading{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .error-loading h1{
        font-size: 2.5em;
        font-family:'Montserrat',
        sans-serif;
        font-weight: 700;
        font-style: italic;
        color: rgb(194, 60, 60);
                text-align: center;

    }
    .error-loading h2 {
        font-size: 2em;
        font-family:'Montserrat',
        sans-serif;
        font-weight: 500;
        color: gray;
        text-align: center;

    }

    .error-loading h3 {
        font-size: 1em;
        font-family:'Merriweather',
        sans-serif;
        color: gray;
                text-align: center;
    }


 
         


