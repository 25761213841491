
.header-container{
    grid-column-start: 1;
    grid-column-end: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 10px;
}

@media screen and (min-width: 1px) and (max-width: 949px){
    .header-card {
        height: 100%;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items:center;
    }

    .header-titles{
        width: 100%;
        height: 70%;
    }

    .header-contact {
        margin-top: 20px;
        width: 100%;
        height: 20%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;

    }

    .contact-method {
        display: flex;
        justify-content: center;
        color: white;
        padding-right: 50px;
    }

    .contact-method p {
        padding-top: 4px;
        font-style: italic;
    }

    .contact-method i {
        display: inline-block;
        vertical-align: middle;
        font-size: 30px;
        padding-right: 10px;
    }
}

@media screen and (min-width: 950px) {

    .header-card{
        height: 100%;
        width: 950px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .header-titles{
        height: 100%;
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }

    .header-contact{
        height: 100%;
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
}



    .contact-method{
        width: 100%;
        color: white;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 3px;
            font-family:'Merriweather',
            sans-serif;
    }

    .contact-method i {
        font-size: 25px;
        color: #F2F876;
    }

    #phone-icon{
        padding-right: 18px;
    }
    #pin-icon {
         padding-right: 25px;
    }
    #mail-icon {
          padding-right: 15px;
    }

    .header-titles h1 {
        font-size: 2.5em;
        color: white;
        text-decoration-line: underline;
        text-decoration-thickness: 3px;
        text-decoration-color: #F2F876;
        text-underline-offset: 10px;

        font-family: 'Montserrat',
                sans-serif;
        font-weight: 700;
    }

    .header-titles h2 {
        font-size: 2em;
        color: white;
        font-family: 'Montserrat',
                sans-serif;
        font-weight: 500;
        font-style: italic;
    }

        .header-titles h3 {
            font-size: 1em;
            color: white;
            font-family: 'Merriweather',
                sans-serif;
        }