@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;1,300;1,400&family=Montserrat:ital,wght@0,300;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  margin: 0;
  padding: 0;
  background-color: #283133 !important;
}

p{
      font-family: 'Judson',
      'sans-serif';
      font-weight: regular;
}

