@import url(https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;1,300;1,400&family=Montserrat:ital,wght@0,300;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  padding: 0;
  background-color: #283133 !important;
}

p{
      font-family: 'Judson',
      'sans-serif';
      font-weight: regular;
}


.body-container{
    height: 100%;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding-bottom: 10px;
}

   .gallery-image img {
       width: 100% !important;
       height: auto !important;
   }

   .gallery-container{
       margin-top: 20px;
   }

   @media screen and (min-width: 00px) and (max-width: 500px) {

       .gallery-container {

            display:inline-block;
            margin-right: auto;
            margin-left: auto;

            width: 100%;
            height: 100%;
            padding: 40px;

            line-height: 0;
            -webkit-column-count: 1;
            -webkit-column-gap: 10px;
            -moz-column-count: 1;
            -moz-column-gap: 10px;
            column-count: 1;
            grid-column-gap: 10px;
            column-gap: 10px;
            background-color: #323e42;
            box-shadow: 0 0 20px;
            border-radius: 10px;
       }
   }

@media screen and (min-width: 500px) and (max-width: 950px){

    .gallery-container {
        display:inline-block;
        margin-right: auto;
        margin-left: auto;

        width: 700px;
        height: 100%;
        padding: 40px;

        line-height: 0;
        -webkit-column-count: 2;
        -webkit-column-gap: 10px;
        -moz-column-count: 2;
        -moz-column-gap: 10px;
        column-count: 2;
        grid-column-gap: 10px;
        column-gap: 10px;
        background-color: #323e42;
        box-shadow: 0 0 20px;
        border-radius: 10px;
       }
}


@media screen and (min-width: 950px) {
    .gallery-container {
        width: 950px;
        overflow: scroll;
        line-height: 0;
        -webkit-column-count: 3;
        -webkit-column-gap: 10px;
         -moz-column-count: 3;
        -moz-column-gap: 10px;
        column-count: 3;
        grid-column-gap: 10px;
        column-gap: 10px;
        background-color: #323e42;
        box-shadow: 0 0 20px;
        border-radius: 10px;;
        padding: 20px;
    }

}

    .gallery-image {
        padding-top: 10px;
    }

    .gallery-image img {
        border-radius: 10px;
    }

    .error-container{
        width: 90%;
        height: 80%;
        background-color:#323e42;
        border-radius: 10px;
        box-shadow: 0 0 15px;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        margin-top: 50px;
        -webkit-align-self: flex-start;
                align-self: flex-start;
    }

    .error-loading{
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .error-loading h1{
        font-size: 2.5em;
        font-family:'Montserrat',
        sans-serif;
        font-weight: 700;
        font-style: italic;
        color: rgb(194, 60, 60);
                text-align: center;

    }
    .error-loading h2 {
        font-size: 2em;
        font-family:'Montserrat',
        sans-serif;
        font-weight: 500;
        color: gray;
        text-align: center;

    }

    .error-loading h3 {
        font-size: 1em;
        font-family:'Merriweather',
        sans-serif;
        color: gray;
                text-align: center;
    }


 
         




.header-container{
    grid-column-start: 1;
    grid-column-end: 4;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
    width: 100%;
    padding: 10px;
}

@media screen and (min-width: 1px) and (max-width: 949px){
    .header-card {
        height: 100%;
        width: 90%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        -webkit-align-items:center;
                align-items:center;
    }

    .header-titles{
        width: 100%;
        height: 70%;
    }

    .header-contact {
        margin-top: 20px;
        width: 100%;
        height: 20%;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: flex-start;
                align-items: flex-start;

    }

    .contact-method {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        color: white;
        padding-right: 50px;
    }

    .contact-method p {
        padding-top: 4px;
        font-style: italic;
    }

    .contact-method i {
        display: inline-block;
        vertical-align: middle;
        font-size: 30px;
        padding-right: 10px;
    }
}

@media screen and (min-width: 950px) {

    .header-card{
        height: 100%;
        width: 950px;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
    }
    .header-titles{
        height: 100%;
        width: 70%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        -webkit-align-items: flex-start;
                align-items: flex-start;
    }

    .header-contact{
        height: 100%;
        width: 30%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        -webkit-align-items: center;
                align-items: center;
    }
}



    .contact-method{
        width: 100%;
        color: white;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        -webkit-align-items: center;
                align-items: center;
        margin: 3px;
            font-family:'Merriweather',
            sans-serif;
    }

    .contact-method i {
        font-size: 25px;
        color: #F2F876;
    }

    #phone-icon{
        padding-right: 18px;
    }
    #pin-icon {
         padding-right: 25px;
    }
    #mail-icon {
          padding-right: 15px;
    }

    .header-titles h1 {
        font-size: 2.5em;
        color: white;
        -webkit-text-decoration-line: underline;
                text-decoration-line: underline;
        text-decoration-thickness: 3px;
        -webkit-text-decoration-color: #F2F876;
                text-decoration-color: #F2F876;
        text-underline-offset: 10px;

        font-family: 'Montserrat',
                sans-serif;
        font-weight: 700;
    }

    .header-titles h2 {
        font-size: 2em;
        color: white;
        font-family: 'Montserrat',
                sans-serif;
        font-weight: 500;
        font-style: italic;
    }

        .header-titles h3 {
            font-size: 1em;
            color: white;
            font-family: 'Merriweather',
                sans-serif;
        }
.landing-container{
    height: 100vh;
    width: 100vw;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.landing-grid{
     display: grid;
     height: 100%;
     width: 100%;
     grid-template-columns: 100%;
     grid-template-rows: 2fr 6fr;
    
}

