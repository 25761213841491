.landing-container{
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.landing-grid{
     display: grid;
     height: 100%;
     width: 100%;
     grid-template-columns: 100%;
     grid-template-rows: 2fr 6fr;
    
}
